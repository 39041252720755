import { graphql } from 'gatsby';
import React from 'react';
import {
  arrayOf, shape, string, bool,
} from 'prop-types';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import { FluidPicture } from '../../../common/picture';
import { pictureFluidType } from '../../../../types/picture';
import { Content } from '../../../common/content';
import { Button } from '../../../common/button';
import { resolve } from '../../../../helpers/urls';
import './product.scss';

const bem = new BEMHelper({
  name: 'content-teaser-product',
});

export const TeaserProduct = ({
  id, headline, description, isNew, image: [image], products: [products],
}) => {
  const { t } = useTranslation();
  return (
    <Content id={id} {...bem()}>
      <div {...bem('details')}>
        <div {...bem('wrapper')}>
          <h2 {...bem('headline')}>
            {isNew && (
            <strong {...bem('label')}>
              <span {...bem('text')}>
                New
                <span {...bem('sr')}>:</span>
              </span>
            </strong>
            )}
            {headline}
          </h2>
          <p {...bem('description')}>{description}</p>
          <Button
            {...bem('link')}
            to={resolve('products:detail', {
              departmentSlug: products.departments[0].slug,
              productSlug: products.slug,
              variantSlug: products?.variants[0].slug,
            })}
            title={products.title ? products.title : t('Click here to view product')}
          >
            {t('View product')}
          </Button>
        </div>
      </div>
      <FluidPicture
        {...image}
        maxWidth={960}
        widths={[240, 480, 960, 1440, 1920]}
        role="presentation"
        {...bem('image')}
      />
    </Content>
  );
};

TeaserProduct.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  description: string.isRequired,
  isNew: bool.isRequired,
  image: arrayOf(pictureFluidType).isRequired,
  products: arrayOf(shape({
    slug: string.isRequired,
    title: string,
    departments: arrayOf(shape({
      slug: string.isRequired,
      color: string.isRequired,
    })),
    variants: arrayOf(shape({
      slug: string.isRequired,
    })),
  })).isRequired,
};

export const fragment = graphql`
  fragment TeaserProductContentFragment on CraftGQL_pageContents_productTeaser_BlockType {
    id
    headline
    description
    isNew
    image {
      ...Picture
      fluid_0: url(width: 240, mode: "crop", position: "") # 0.25
      fluid_1: url(width: 480, mode: "crop", position: "") # 0.5
      fluid_2: url(width: 960, mode: "crop", position: "") # max width
      fluid_3: url(width: 1440, mode: "crop", position: "") # 1.5
      fluid_4: url(width: 1920, mode: "crop", position: "") # 2
    }
    products {
      ...on CraftGQL_products_product_Entry {
        slug
        title
        departments: productDepartment {
          ...on CraftGQL_departments_department_Entry {
            slug
            color: departmentColor
          }
        }
        variants: productVariants(limit: 1) {
          ...on CraftGQL_variants_variants_Entry {
            slug
          }
        }
      }
    }
  }
`;
